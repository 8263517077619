import React from 'react'
import './footer.css'

import { useStateContext } from '../../contexts/ContextProvider';
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

// import signup from '../../data/signup.png'
import signup from '../../data/btn-playtest.png'
import newsletter from '../../data/newsletter.png'

const Footer = () => {
  const { setShowPopup, setPopupType } = useStateContext();

  return (
    <div className='main__footer'>
      <div className='main__footer-interaction'>
      <Link to='https://calendly.com/Xreality' target="_blank" rel="noopener noreferrer">

        <button type='button'  className='transform transition-transform duration-300 hover:scale-110' onClick={() => {  }}>
          <img src={signup} alt='sign up' />
        </button>
        </Link>

        <button className='main__footer-interaction-mobile-hide transform transition-transform duration-300 hover:scale-110' type='button'  onClick={() => { setShowPopup(true); setPopupType('newsletter') }}>
          <img src={newsletter} alt='newsletter' />
        </button>

      </div>
      <div className='text-white flex space-x-4 '> 
      <a href='https://x.com/levityrace' className="hover:underline" target="_blank" rel="noopener noreferrer">
        {/* <FontAwesomeIcon icon={faXTwitter} /> */}follow us
      </a>
      
      <a href='mailto:hello@levityrace.com' className="hover:underline">
        {/* <FontAwesomeIcon icon={faEnvelope} /> */}contact us
      </a>
      <Link to='/privacy' className='hover:underline'>Privacy Policy</Link> </div>
    <div className='text-xs pt-5 text-white'> Copyright CARV Labs, 2024.</div>
    </div>

  )
}

export default Footer