import React from 'react'
// import { useRef } from 'react'
import './firstsection.css'
import abstract from '../../data/d7-ABSTRACT_03_alpha.png'
// import img from '../../data/Blue-Racer_Sitting_Alpha.png'
import img from '../../data/Blue-Racer_Sitting_hd.png'
import accent from '../../data/Accent.png'


const FirstSection = () => {
  // const firstRef = useRef()
  return (
    <>
        {/* <div id="overlay_firstsection"></div> */}

    <div className='main__firstsection main__firstsection__bg' id='firstsec'>
      <div className='main__firstsection__wrapper'>
        <div className='main__firstsection__abstract'>
          <img src={abstract} alt='abstract' />
        </div>
        <div className='main__firstsection__img main__firstsection__img_float'>
          <img src={img} alt='blue racer' />
        </div>
      </div>

      <div className='main__firstsection__textbox'>
        <div className='main__firstsection__textbox-accent'>
          <img src={accent} alt='accent' />
        </div>
        {/* <h1>Lorem ipsum dolor</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
       */}
        <h1>Race against your friends... or enemies</h1>
        <p>Blast off in an intense player versus player death race full of blistering speeds and gigantic explosions, make sure to bring an extra pair of underwear.</p>
      
      
      </div>
    </div>
    </>
  )
}

export default FirstSection