import './App.css';

import { Footer } from './containers';
import { Navbar } from './components';
import { Home, Lore, LevitySpace, Studio, PrivacyPolicy } from './pages';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LapTimesTable from './components/laptimestable/LapTimesTable';
//import { FiSettings } from 'react-icons/fi';
//import { TooltipComponent } from '@syncfusion/ej2-react-popups';


function App() {
  return (
    <BrowserRouter>
      <div className="App">

      <div className="gradient__bg">
          <Navbar />
        </div>

        <Routes>
          {/* Public Spaces */}
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/lore' element={<Lore />} />
          <Route path='/studio' element={<Studio />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />
          {/* <Route path='/playersignup' element={<Form />} /> */}

          {/* Members Area */}
          <Route path='/levityspace' element={<LevitySpace />} />

        </Routes>

        <div className="gradient__bg">
          <Footer />
          </div>
      </div>
    </BrowserRouter>

  );
}

export default App;
