import React from 'react'
import './secondsection.css'

import abstract from '../../data/d7-ABSTRACT_02_alpha.png'
// import img from '../../data/Drone_Beauty_Eye.png'
import img from '../../data/Drone_Beauty_hd.png'
import accent from '../../data/Accent.png'


const SecondSection = () => {
  return (
    <div className='main__secondsection main__secondsection__bg' id='secondsec'>
      <div className='main__secondsection__wrapper'>
        <div className='main__secondsection__abstract'>
          <img src={abstract} alt='abstract' />
        </div>
        <div className='main__secondsection__img border-0 main__secondsection__img_float'>
          <img src={img} alt='levy drone' />
        </div>
      </div>
      <div className='main__secondsection__textbox'>
        <div className='main__secondsection__textbox-accent'>
          <img src={accent} alt='accent' />
        </div>
        {/* <h1>Lorem ipsum dolor</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
       */}
        <h1>Upgrade, collect and modify your race kit</h1>
        <p>Whether it’s your hoverboard, race suit, or attack drone, there are many ways to customize and show off your racer’s personality through rewards and items found in the LEVITY boardshop.</p>
      
      </div>
    </div>
  )
}

export default SecondSection