import React from 'react'
import './firstmidsection.css'
import LapTimesTable from '../../components/laptimestable/LapTimesTable'
const FirstMidSection = () => {
  return (
    <div className='main__firstmidsection'>
        <div className='main__firstmidsection__textbox'>
        {/* <div className='main__firstsection__textbox-accent'>
          <img src={accent} alt='accent' />
        </div> */}
    
        <h1>Get Ready To Ride</h1>
          <p>Experience a thrilling new way to race against others in VR in the hottest upcoming multiplayer combat racing arcade adventure, LEVITY.</p>
        </div>
    </div>
  )
}

export default FirstMidSection