import React from 'react'
import { FirstSection, Footer, Hero, SecondSection, ThirdSection, FirstMidSection, SecondMidSection, ThirdMidSection, FourthMidSection, FithSection, FithMidSection  } from '../containers';
import { Popup } from '../components';
import bgvideo from '../data/bgvideo2.mp4'
import xlogo from '../data/xlogo.avif'
import dlogo from '../data/discordlogo.jpeg'

import { useStateContext } from '../contexts/ContextProvider';

const Home = () => {
    const { showPopup } = useStateContext();

    return (
        <>
            {showPopup && (
                <Popup />
            )}
            {/* <div className='video__bg'> */}
                {/* <video src={bgvideo} autoPlay muted loop playsInline id="mvideo" /> */}
                {/* <Hero /> */}
                {/* <Drawer /> */}
            {/* </div> */}
            <Hero />
            <FirstMidSection />
            <FirstSection />
            <SecondMidSection /> 
            <SecondSection />
            <ThirdMidSection />
            <ThirdSection />
            <FourthMidSection />

            {/* <FithSection /> */}
            {/* <FithMidSection /> */}
            {/* <Footer /> */}
            {/* <div className='main__hero-image__socials'>
                <a href='https://www.x.com/LevityRace' target="_blank" rel="noreferrer">
                    <img src={xlogo} alt='Follow Us on X/Twitter' />
                </a>
            </div>
            <div className='main__hero-image__socials_2'>
                <img src={dlogo} alt='Coming Soon' />
            </div> */}

        </>
    )
}

export default Home