import React from 'react'
import './thirdmidsection.css'

const ThirdMidSection = () => {
  return (
    <div className='main__thirdmidsection'>
         <div className='main__thirdmidsection__textbox'>
        {/* <div className='main__firstsection__textbox-accent'>
          <img src={accent} alt='accent' />
        </div> */}
       <h1>Upgrade, collect and modify your race kit</h1>
        <p>Whether it’s your hoverboard, race suit, or attack drone, there are many ways to customize and show off your racer’s personality through rewards and items found in the LEVITY boardshop.</p>
      </div>
    </div>
  )
}

export default ThirdMidSection