// @app/components/PrivacyPolicy.tsx

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy p-10 bg-gray-800 text-white">
      <h1 className="text-3xl font-bold">Levity Race - Privacy Policy</h1>
      <p className="text-sm mb-6">Effective Date: 11/10/2024</p>

      <p className="mb-4">
        LEVRTV LLC ("we", "us", "our"), we respect your privacy and are committed to protecting the personal information
        you provide when using LEVITY RACE ("Game"). This Privacy Policy explains how we collect, use, disclose, and
        protect your information.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">1. Information We Collect</h2>
      <p className="mb-4">
        When you sign up to use LEVITY Race, we collect the following types of information:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Account Information:</strong> When you create an account, we collect your email address and other information you choose to provide.</li>
        <li><strong>Usage Data:</strong> We collect anonymous information about how you interact with the game, including gameplay data, IP address, device information, and browser type. This data is collected automatically as you use the game.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">2. How We Use Your Information</h2>
      <p className="mb-4">
        We use the information we collect to:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>Provide, maintain, and improve the Game.</li>
        <li>Process payments through Stripe for in-game purchases.</li>
        <li>Respond to your inquiries and provide support via  <a href="mailto:hello@levityrace.com" className="hover:underline"> hello@levityrace.com</a>.</li>
        <li>Analyze usage to improve our services and ensure the best experience.</li>
        <li>Ensure compliance with our Terms and Conditions, including preventing fraud and abuse.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">3. Cookies and Tracking Technologies</h2>
      <p className="mb-4">
        We may use cookies and similar tracking technologies to enhance your experience in the Game. Cookies help us
        understand how you interact with the Game and improve our services. You can disable cookies through your browser
        settings, but doing so may limit your ability to use some features of the Game.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">4. Data Sharing and Disclosure</h2>
      <p className="mb-4">
        We do not sell or share your personal data with third parties for marketing purposes. We may share your data
        under the following circumstances:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Service Providers:</strong> We may share your data with third-party service providers who help us deliver the Game (e.g., hosting providers, payment processors).</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information to comply with legal obligations, enforce our Terms and Conditions, or protect the rights and safety of LEVRTV LLC, our users, or others.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">5. Data Retention</h2>
      <p className="mb-4">
        We retain your personal data for as long as your account is active or as necessary to provide you with services.
        You may close your account at any time, and we will delete your personal data, except as required for legal
        obligations.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">6. Data Security</h2>
      <p className="mb-4">
        We take appropriate measures to protect your personal data from unauthorized access, use, or disclosure.
        However, no security measure is completely foolproof, and we cannot guarantee the absolute security of your
        data.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">7. Your Rights</h2>
      <p className="mb-4">
        If you are located in the European Economic Area (EEA), you have certain rights under the General Data
        Protection Regulation (GDPR), including:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>The right to access your data.</li>
        <li>The right to request correction or deletion of your data.</li>
        <li>The right to object to processing or restrict data processing.</li>
      </ul>
      <p className="mb-4">
        To exercise these rights, please contact us at hello@levityrace.com.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">8. Children&rsquo;s Privacy</h2>
      <p className="mb-4">
        LEVITY Race is intended for users aged 13 and older. We do not knowingly collect personal information from
        children under the age of 13. If we discover that we have collected personal information not in accordance with
        local or regional regulations and laws, we will promptly delete it.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">9. Changes to This Privacy Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. We will notify you of any changes by updating the effective
        date at the top of the policy. We encourage you to review this policy periodically.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">10. Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy, please contact us at  <a href="mailto:hello@levityrace.com" className="hover:underline"> hello@levityrace.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
